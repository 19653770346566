import TYPES from '@/types';

import Vue from 'vue';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class BucketShortTimeCreatedSuccessfullyViewModel {
  @Inject(TYPES.I18N)
  readonly translator!: Translator;

  readonly i18n_namespace = 'components.flagship.flagship-goals.create-goal.bucket.short-time-created-successfully';

  private readonly view!: Vue;

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  public constructor(view: Vue) {
    this.view = view;
  }

  goToDashboardGoals = () => {
    window.location.href = '/goals-dashboard';
  }

  goToWealth = () => {
    window.location.href = '/sowos-wealth';
  }
}
